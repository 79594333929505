import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Grid from "components/Grid/Grid";
import { LineHeader, CTA, Title } from "components/Module/Module";
import { SectionMargin } from "components/Section/Section";
import { Square } from "modules/EditorialSummary/EditorialSummary";
import { getBlogUrl } from "lib/helpers";


const CustomWrapper = styled(SectionMargin)`
  margin-bottom: 0;
`;

const CustomSection = styled(SectionMargin)`
  margin: 0;
`;

const Column = styled.div`
  grid-column: span 6;
`;

const Related = ({ prevNode, nextNode }) => {
  return (
    <CustomWrapper fullbleed>
      <LineHeader>
        <Title>RELATED STORIES</Title>
        <CTA><Link to="/library">SEE ALL EDITORIAL</Link></CTA>
      </LineHeader>
      <CustomSection>
        <Grid>
          <Column>
            {prevNode && (
              <Link
                to={getBlogUrl(prevNode.publishedAt, prevNode.slug.current)}
              >
                <Square
                  title={prevNode.title}
                  displayTitle={prevNode._rawDisplayTitle}
                  category="life"
                  image={prevNode.mainImage.asset}
                />
              </Link>
            )}
          </Column>
          <Column>
          {nextNode && (
              <Link
                to={getBlogUrl(nextNode.publishedAt, nextNode.slug.current)}
              >
                <Square
                  title={nextNode.title}
                  displayTitle={nextNode._rawDisplayTitle}
                  category="life"
                  image={nextNode.mainImage.asset}
                />
              </Link>
            )}
          </Column>
        </Grid>
      </CustomSection>
    </CustomWrapper>
  );
};

export default Related;
