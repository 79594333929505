import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { Header, CTA, Title } from "components/Module/Module";
import { SectionMargin } from "components/Section/Section";
import ShopStoryProduct from "modules/ShopStoryProduct/ShopStoryProduct";
import { media } from "utils/mediaQuery";

const CustomGrid = styled(Grid)`
  position: relative;
  margin: 50px 0 0;
`;

const ShopStory = ({ products }) => {
  return (
    <SectionMargin large>
      <Header>
        <Title>Shop the story</Title>
        <CTA>the new moon shop</CTA>
      </Header>
      <CustomGrid>
        {products &&
          products.map((product, i) => {
            return <ShopStoryProduct key={i} product={product} />;
          })}
      </CustomGrid>
    </SectionMargin>
  )
}

export default ShopStory;
