import React from "react";
import styled from "styled-components";
import { LazyBlurImage } from "@manualengineering/react-lazyblur";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";
import { media } from "utils/mediaQuery";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  grid-column: span 3;
`;

const Brand = styled.h3`
  ${fontstack.secondary}
  ${type("subheaderD")}
  color: var(--brand-grey2);
  text-transform: uppercase;
  margin: 20px 0 0;

  ${media.large` 
    ${type("subheaderC")}
  `}
`;

const Title = styled.h2`
  ${fontstack.primary}
  ${type("body2")}
  color: var(--brand-black);
  margin: 0;

  ${media.large` 
    ${type("body2")}
  `}
`;

const Price = styled.div`
  ${fontstack.secondary}
  ${type("subheaderD")}
  color: var(--brand-grey2);
  margin: 15px 0 0;

  ${media.large` 
    ${type("subheaderC")}
  `}
`;

const ShopStoryProduct = ({ product }) => {
  const productObject = JSON.parse(product);
  const variants = productObject.variants;
  const firstVariant = variants[0];
  const price = firstVariant.price;
  const image = firstVariant.image;

  console.log(image);

  const baseUrl = process.env.GATSBY_SHOPIFY_URL || "https://the-new-moon-store.myshopify.com";

  return (
    <Wrapper>
      <a href={`${baseUrl}/products/${productObject.handle}`} target="_blank">
        {image && (
          <LazyBlurImage
            src={`${image.src}`}
            placeholder={{
              src: null,
              width: 1200,
              height: 1200,
            }}
          />
        )}
        <Brand>{productObject.vendor}</Brand>
        <Title>{productObject.title}</Title>
        <Price>${price}</Price>
      </a>
    </Wrapper>
  );
};

export default ShopStoryProduct;
