import { graphql } from "gatsby";
import BlogPost from "../components/blog-post";
import styled from "styled-components";
import React from "react";
import { Link } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import { LineHeader, CTA } from "components/Module/Module";
import Layout from "../containers/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import { toPlainText } from "../lib/helpers";
import { getBlogUrl } from "lib/helpers";

export const query = graphql`
  query BlogPostTemplateQuery(
    $id: String!
    $prev_id: String!
    $next_id: String!
  ) {
    prevPost: sanityPost(id: { eq: $prev_id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      title
      slug {
        current
      }
      _rawDisplayTitle
      mainImage {
        asset {
          gatsbyImageData
        }
        alt
      }
    }
    nextPost: sanityPost(id: { eq: $next_id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      title
      slug {
        current
      }
      _rawDisplayTitle
      mainImage {
        asset {
          gatsbyImageData
        }
        alt
      }
    }
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt(formatString: "D MMM YYYY")
      categories {
        _id
        title
      }
      title
      slug {
        current
      }
      authors {
        _key
        author {
          name
        }
      }
      heroImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          gatsbyImage(quality: 100, width: 1150)
        }
        alt
      }
      mainImage {
        alt
        asset {
          gatsbyImageData(placeholder: BLURRED)
          url
          _id
        }
      }
      _rawSubtitle
      _rawDisplayTitle
      pageBuilder {
        ... on SanityEditorialPullQuoteParagraph {
          pQuote: quote {
            _key
            _type
            column
            quote
          }
          _key
          _type
          paragraphs {
            _key
            _type
            _rawContent
          }
        }
        ... on SanityEditorialImage1 {
          _key
          _type
          image {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          column
        }
        ... on SanityEditorialImage2 {
          _key
          _type
          images {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
            alt
            caption
          }
        }
        ... on SanityEditorialParagraph {
          _key
          _type
          _rawContent
          column
        }
        ... on SanityEditorialQuote {
          _key
          _type
          column
          quote
        }
        ... on SanityEditorialImage3 {
          _key
          _type
          image {
            caption
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          video {
            autoPlay
            controls
            loop
            muted
            url
          }
        }
        ... on SanityEditorialImage4 {
          _key
          _type
          image {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          imageMobile {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      shopModule
      related {
        _rawDisplayTitle
        publishedAt
        slug {
          current
        }
        title
        mainImage {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      imagesContributors {
        label
        value
      }
    }
  }
`;

const CustomLineHeader = styled(LineHeader)`
  margin: 0;
  height: 175px;
  display: flex;
  align-items: flex-end;

  & > div {
    width: 100%;
  }
`;

const BlogPostTemplate = (props) => {
  const { data, errors } = props;
  const post = data && data.post;
  const prevPost = data && data.prevPost;
  const nextPost = data && data.nextPost;
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}

      {post && (
        <SEO
          title={post.title || "Untitled"}
          description={toPlainText(post._rawSubtitle)}
          image={post.mainImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <CustomLineHeader>
        <CTA>
          <Link to={getBlogUrl(prevPost.publishedAt, prevPost.slug.current)}>{"<"} Previous story</Link>
        </CTA>
        <CTA to="/">
          <Link to={getBlogUrl(nextPost.publishedAt, nextPost.slug.current)}>Next story {">"}</Link>
        </CTA>
      </CustomLineHeader>
      {post && <BlogPost prev={prevPost} next={nextPost} {...post} />}
    </Layout>
  );
};

export default BlogPostTemplate;
