import * as styles from "./blog-post.module.css";
import { differenceInDays, formatDistance, format } from "date-fns";
import AuthorList from "./author-list";
import Container from "./container";
import PortableText from "./portableText";
import React from "react";
import {
  pageBuilder as Builder,
  Hero,
  Share,
} from "modules/Editorial/Editorial";
import ShopStory from "modules/ShopStory/ShopStory";
import Related from "modules/Editorial/Related";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

function BlogPost(props) {
  const { _rawBody, authors, categories, title, mainImage, publishedAt, shopModule, related, pageBuilder } =
    props;

  const relatedOne = (related || null)[0];
  const relatedTwo = (related || null)[1];

  return (
    <article className={styles.root}>
      <Hero node={props} />
      {Builder(pageBuilder)}
      <Share />
      {shopModule && shopModule.length > 0 ? (
        <ShopStory products={shopModule} />
      ) : null}
      <Related prevNode={relatedOne} nextNode={relatedTwo} />
    </article>
  );
}

export default BlogPost;
