import React from "react";
import styled from "styled-components";
import { SectionMargin } from "components/Section/Section";
import PortableText from "components/portableText";
import TextForm from "modules/TextForm/TextForm";
import { LineHeader, CTA, Title } from "components/Module/Module";
import ImageCaption from "modules/ImageCaption/ImageCaption";
import PullQuote from "components/PullQuote/PullQuote";
import Grid from "components/Grid/Grid";
import { media } from "utils/mediaQuery";
import VideoEmbed from "./VideoEmbed";

const InnerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 6px;
  grid-column: span 6;

  ${media.large` 
    grid-column-gap: 16px;
    grid-column: 2 / span 10;
  `}
`;

const Column = styled.div`
  grid-column: span 2;

  ${media.large` 
    grid-row: 1;
    grid-column: ${(props) =>
      props.align === "right" ? 2 : props.align === "left" ? 1 : "auto"};
  `}
`;

const FullWrapper = styled.div`
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  ${media.large` 
    width: 100%;
    margin-left: 0;
    transform: translateX(0);
  `}
`;

const FullColumn = styled.div`
  grid-column: span 2;
`;

const SubModule = styled.div`
  margin: 0 0 40px;

  ${media.large` 
      margin: 0;
  `}

  &:not(:last-child) {
    margin: 0 0 40px;
    ${media.large` 
      margin: 0 0 50px;
    `}
  }
`;

const ImageCaptionDesktop = styled(ImageCaption)`
  display: none;
  ${media.large` 
    display: block;
  `}
`;

const ImageCaptionMobile = styled(ImageCaption)`
  display: block;
  ${media.large` 
    display: none;
  `}
`;

const SectionWrapper = styled(SectionMargin)`
  margin-bottom: 0;
`;

const CustomSection = styled(SectionMargin)`
  margin: 0;
`;

export const moduleSelector = {
  sectionImage: (data, props) => {
    const { image, video, title } = data;

    return (
      <SectionWrapper fullbleed {...props}>
        <LineHeader>
          <Title>{title}</Title>
        </LineHeader>
        <CustomSection>
          <Grid>
            <InnerGrid>
              <FullColumn>
                {image ? (
                  <ImageCaption
                    image={data.image.asset}
                    alt={data.image.alt}
                    caption={data.image.caption}
                  />
                ) : video ? (
                  <VideoEmbed video={video} />
                ) : null}
              </FullColumn>
            </InnerGrid>
          </Grid>
        </CustomSection>
      </SectionWrapper>
    );
  },
  sectionColumnSection: (data, props) => {
    const { title } = data;

    return (
      <SectionWrapper fullbleed {...props}>
        <LineHeader>
          <Title>{title}</Title>
        </LineHeader>
        <CustomSection>
          <Grid>
            <InnerGrid>
              <Column align="left">
                {data.columnOne && data.columnOne.length > 0
                  ? data.columnOne.map((data) => sectionParagraph(data))
                  : null}
              </Column>
              <Column align="right">
                {data.columnTwo && data.columnTwo.length > 0
                  ? data.columnTwo.map((data) => sectionParagraph(data))
                  : null}
              </Column>
            </InnerGrid>
          </Grid>
        </CustomSection>
      </SectionWrapper>
    );
  },
};

const sectionBuilder = (modules, isSub) => {

  let components = [];
  modules.forEach((module) => {
    if (module && moduleSelector[module._type]) {
      components.push(moduleSelector[module._type](module, isSub));
    }
  });

  return components;
};

export default sectionBuilder;

const sectionParagraph = (data) => {
  return (
    <SubModule>
      <PortableText blocks={data._rawContent} />
    </SubModule>
  );
};
