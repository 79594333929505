import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PortableText from "components/portableText";
import Grid from "components/Grid/Grid";
import { SectionMargin } from "components/Section/Section";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";
import { media } from "utils/mediaQuery";

const CustomGrid = styled(Grid)`
  grid-row-gap: 0;
`;

const Content = styled.div`
  position: relative;
  padding: 0 15px;

  ${media.large` 
    padding: 0 50px;
  `}
`;

const HalfSection = styled.div`
  grid-column: span 6;

  ${media.large` 
    display: flex;
    align-items: center;
  `}
`;

const Categories = styled.div`
  display: flex;
  margin: 25px 0 0;

  ${media.large` 
    margin: 0;
  `}
`;

const Category = styled.div`
  ${fontstack.secondary}
  ${type("subheaderC")}
  color: var(--brand-grey2);
  text-transform: uppercase;

  &:not(:first-child):before {
    content: ", ";
    white-space: pre;
    display: inline-block;
  }

  ${media.large` 
    ${type("subheaderB")}
  `}
`;

const Title = styled.h1`
  ${fontstack.default}
  ${type("titleA")}
  color: var(--brand-black);
  margin: 8px 0 14px;
  & > p {
    margin: 0;
  }

  ${media.large` 
    ${type("headlineB")}
  `}
`;

const SubTitle = styled.div`
  ${fontstack.default}
  ${type("body1")}
  color: var(--brand-black);
  & > p {
    margin: 0;
  }

  ${media.large` 
   ${type("body2")}
  `}
`;

const Date = styled.div`
  ${fontstack.secondary}
  ${type("subheaderC")}
  color: var(--brand-grey1);
  margin: 0 0 20px;
  text-transform: uppercase;

  ${media.large` 
    ${type("subheaderB")}
  `}
`;

const Credit = styled.div`
  ${fontstack.secondary}
  ${type("subheaderC")}
  color: var(--brand-grey1);

  ${media.large` 
    ${type("subheaderB")}
  `}
`;

const Hero = ({ node }) => {
  const imageObject = node.heroImage ? node.heroImage.asset.gatsbyImage : null;

  return (
    <CustomGrid>
      <HalfSection>
        <Content>
          <Categories>
            {node.categories &&
              node.categories.map((category, i) => (
                <Category key={i}>{category.title}</Category>
              ))}
          </Categories>
          {node._rawDisplayTitle && (
            <Title>
              <PortableText blocks={node._rawDisplayTitle} />
            </Title>
          )}
          {node._rawSubtitle && (
            <SubTitle>
              <PortableText blocks={node._rawSubtitle} />
            </SubTitle>
          )}
          <SectionMargin top small fullbleed>
            <Date>{node.publishedAt}</Date>
            { node.authors && node.authors.length > 0 ? (
              <Credit>
                Words by:
                {node.authors &&
                  node.authors.map((obj, i) => {
                    if (i === node.authors.length - 1) {
                      return <> {obj.author.name}</>;
                    }

                    return <> {obj.author.name},</>;
                  })}
              </Credit>
            ) : null}
            {node.imagesContributors && node.imagesContributors.length > 0 && (
              <Credit>
                Images by:
                {node.imagesContributors.map((contributor, i) => {
                  if (i === node.imagesContributors.length - 1) {
                    return <> {contributor.label}</>;
                  }

                  return <> {contributor.label},</>;
                })}
              </Credit>
            )}
          </SectionMargin>
        </Content>
      </HalfSection>
      <HalfSection>
        <GatsbyImage image={imageObject} layout="fullWidth" style={{width: "100%"}} />
      </HalfSection>
    </CustomGrid>
  );
};

export default Hero;
